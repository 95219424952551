<template>
  <card>
    <template slot="header">
      <p class="card-header-title">Invoices</p>
    </template>

    <template slot="body">
      <invoices-table :user-id="userId" />
    </template>
  </card>
</template>

<script>
export default {
  name: "Invoices",
  components: {
    "invoices-table": () => import("@shared/invoices/_invoicesTable")
  },
  props: {
    userId: {
      default: null,
      validator: value => {
        return ["string", "null"].includes(typeof value);
      }
    }
  }
};
</script>
